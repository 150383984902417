<template>
  <b-modal
    id="create-booking-verify-and-confirm-price"
    title="Kiểm tra thông tin giá vé"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    no-close-on-backdrop
    :body-class="loading ? 'my-2' : ''"
    size="lg"
    @show="showHandle"
    @hide="hideModal"
  >
    <template #modal-footer="{ cancel }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill"
        @click="cancel()"
      >
        {{ $t('flight.Back') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-gradient border-right-0"
        pill
        :disabled="!payments"
        @click="okHandle"
      >
        <div class="d-flex-center px-25">
          Giữ chỗ
        </div>
      </b-button>
    </template>

    <b-overlay
      :show="loading"
      rounded="sm"
      no-fade
      class="my-2"
      spinner-variant="primary"
      variant="transparent"
      opacity="0.85"
    >
      <b-card
        v-if="payments"
        class="mb-0"
        body-class="py-50"
      >
        <div
          v-for="(trip, tripIndex) of bookingData.itineraries"
          :key="tripIndex"
        >
          <div class="font-weight-bolder mb-50">
            Hành trình {{ bookingData.itineraries.length > 1 ? `${tripIndex + 1}` : ':' }}
          </div>
          <b-card
            v-for="(segment, segmentIndex) of trip.segments"
            :key="segmentIndex"
            class="font-weight-bolder border px-2"
            no-body
          >
            <div class="my-75 fw-700">
              <span class="mr-50 text-nowrap">{{ `${resolveAirlineFlightNumber(segment.airline, segment.flightNumber)}` }}</span>
              <span class="mr-50 text-nowrap">{{ `${segment.bookingClass}` }}</span>
              <span class="mr-50 text-nowrap">{{ `${convertISODateTime(segment.departureTime).dayMonth}` }}</span>
              <span class="mr-50 text-nowrap">{{ `${segment.departure}${segment.arrival}` }}</span>
              <span class="mr-50 text-nowrap">{{ `${convertISODateTime(segment.departureTime).hourMin}` }}</span>
              <span>{{ `${convertISODateTime(segment.arrivalTime).hourMin}` }}</span>
            </div>
          </b-card>
        </div>
        <!-- <BAlert
          v-if="isTransitDomesticVN1A"
          show
          variant="danger"
          class="px-2 py-25 fw-700 d-flex gap-2"
        >
          <IAmIcon
            icon="infoCircleOutline"
            size="20"
          />
          <Stack gap="0">
            <div>VNA chưa hỗ trợ đặt các chuyến bay quốc tế có điểm dừng tại các sân bay nội địa.</div>
            <div>Vui lòng liên hệ booker để thao tác.</div>
          </Stack>
        </BAlert> -->
        <div>
          <div class="mb-50 font-weight-bolder">
            Chi tiết giá:
          </div>
          <b-table
            outline
            responsive
            bordered
            :items="payments"
            :fields="['paxs', 'price', 'tax', 'total']"
          >
            <!--'passenger', 'paxs', 'price', 'tax' -->
            <template
              v-for="column in ['paxs', 'price', 'tax', 'total']"
              #[`head(${column})`]="data"
            >
              <span
                :key="column"
                class="text-dark text-nowrap"
              >
                {{ $t(`reservation.${data.label.toLowerCase()}`) }}
              </span>
            </template>

            <!-- NOTE PING Long thêm sau - lấy name pax theo index có case lỗi -->
            <!-- <template #cell(passenger)="{item}">
              {{ `${item.lastName} / ${item.firstName}` }}
            </template> -->
            <template #cell(paxs)="{item}">
              {{ $t(`reservation.${item.paxType}`) }}
            </template>
            <template #cell(price)="{item}">
              {{ `${formatCurrency(item.pricingInfo.netFare)}` }}
            </template>
            <template #cell(tax)="{item}">
              {{ `${formatCurrency(item.pricingInfo.taxCalc)}` }}
            </template>
            <template #cell(total)="{item}">
              <div class="font-weight-bolder">
                {{ `${formatCurrency(item.pricingInfo.totalCalc)}` }}
              </div>
            </template>
          </b-table>
        </div>
        <div
          v-if="totalDiscountAmount"
          class="text-right mb-50"
        >
          <span class="mr-2">
            Chiết khấu <em>(tạm tính)</em>:
          </span>

          <span class="font-medium-3 font-weight-bolder">
            {{ formatCurrency(totalDiscountAmount) }}
          </span>
        </div>

        <div
          v-if="!isEmpty(payments)"
          class="text-warning"
        >
          <div class="text-right mb-50">
            <span class="mr-2">
              Tổng tiền hành trình:
            </span>

            <span class="font-medium-3 fw-700">
              {{ getTotalOneTrip(payments) }}
            </span>
          </div>
          <div class="text-right">
            <span class="font-italic">Lưu ý: Tổng tiền chưa bao gồm phí dịch vụ</span>
          </div>
        </div>
      </b-card>

      <!-- SECTION - ( Add-ons Service) Details -->
      <b-card
        v-if="!loading"
        class="border mt-1"
        :class="isEmpty(addonDetails) ? 'd-none': ''"
      >
        <b class="font-medium-3">
          {{ $t('flight.addons') }}
        </b>

        <b-table-lite
          bordered
          responsive
          class="mb-1 rounded"
          thead-class="text-nowrap fw-600 text-center"
          tbody-class="text-nowrap fw-600 text-center"
          :items="addonDetails"
          :fields="addonsPriceColumns"
        >
          <template
            v-for="column in addonsPriceColumns"
            #[`head(${column.key})`]="data"
          >
            <div
              :key="column.label"
              class="text-dark"
            >
              <span>
                {{ $t(`flight.${data.label}`) }}
              </span>
            </div>
          </template>

          <template #cell(Passenger)="data">
            <span
              class="text-black text-nowrap font-weight-bolder"
              :class="isMobileView ? 'font-small-3' : 'font-medium-1'"
            >
              {{ (data.item.firstName && data.item.lastName)
                ? `${data.item.lastName} ${data.item.firstName}`
                : `${data.item.paxType} #${data.item.paxId}` }}
            </span>
          </template>

          <template #cell(flight_segment)="{item}">
            <h5 class="text-dark text-nowrap">
              {{ item.flights }}
            </h5>
          </template>

          <template #cell(Airline)="data">
            <h5 class="text-dark">
              {{ data.item.airline }}
            </h5>
          </template>

          <template #cell(Service)="data">
            <h5
              class="text-dark"
              style="min-width: 170px;"
            >
              {{ data.item.serviceName }}
            </h5>
          </template>

          <template #cell(Price)="{item}">
            <h5 class="text-dark text-right">
              {{ formatCurrency(item.addonPrice) }}
            </h5>
          </template>

          <template #cell(Amount)="data">
            <div class="text-dark">
              <div class="d-flex justify-content-center align-items-center">
                <b-button
                  v-if="data.item.ssrName === 'ssrBags'"
                  :id="`btn-decrease-${data.item.ssrId}`"
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="flat-danger"
                  class="btn-icon rounded-circle d-none"
                  :disabled="data.item.amount === 1"
                  @click="$emit('handle-service', 'decrease', data.item)"
                >
                  <feather-icon
                    class="font-weight-bolder"
                    icon="MinusIcon"
                  />
                </b-button>
                <span
                  style="min-width:25px"
                  class="d-inline-block"
                >
                  {{ data.item.amount }}
                </span>
                <b-button
                  v-if="data.item.ssrName === 'ssrBags'"
                  :id="`btn-increase-${data.item.ssrId}`"
                  v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                  variant="flat-info"
                  class="btn-icon rounded-circle font-weight-bolder d-none"
                  @click="$emit('handle-service', 'increase', data.item)"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </div>
            </div>
          </template>

          <template #cell(Total)="{ item }">
            <h5 class="text-dark text-right">
              <b>
                {{ formatCurrency(item.addonPrice * item.amount) }}
              </b>
            </h5>
          </template>
        </b-table-lite>

        <hr>

        <!--Total trip -->
        <div class="d-flex justify-content-end">
          <div>
            <strong class="d-block font-medium-2">{{ $t('flight.total') }}</strong>
            <i>({{ $t('flight.fareText') }})</i>
          </div>
          <strong class="font-medium-2">{{ formatCurrency(totalPriceAddon) }}</strong>
        </div>
      </b-card>
      <!-- !SECTION -->

      <!-- SECTION - ( Seat Map ) Details -->
      <b-card
        v-if="!loading"
        class="border mt-1"
        :class="isEmpty(selectedSeatsDetail) ? 'd-none' : ''"
      >
        <b class="font-medium-3">
          {{ $t('flight.seatDetails') }}
        </b>

        <hr>

        <b-table-lite
          bordered
          responsive
          class="mb-1 rounded"
          thead-class="text-nowrap fw-600 text-center"
          :items="selectedSeatsDetail"
          :fields="seatsPriceColumns"
        >
          <template
            v-for="column in seatsPriceColumns"
            #[`head(${column.key})`]="data"
          >
            <span
              :key="column.label"
              class="text-dark"
            >
              {{ $t(`flight.${data.label}`) }}
            </span>
          </template>
          <template #cell(Passenger)="data">
            <h5 class="text-dark">
              <b>
                {{ (data.item.passenger.firstName && data.item.passenger.lastName)
                  ? `${data.item.passenger.lastName} ${data.item.passenger.firstName}`
                  : `${data.item.passenger.paxType} #${data.item.passenger.paxId}` }}
              </b>
            </h5>
          </template>
          <template #cell(flight_segment)="{item}">
            <h5 class="text-dark text-nowrap">
              {{ item.segment.segmentFlights }}
            </h5>
          </template>
          <template #cell(Airline)="{item}">
            <h5 class="text-dark">
              {{ item.segment.airline }}
            </h5>
          </template>
          <template #cell(Seat)="{item}">
            <h5 class="text-dark">
              {{ item.seat.seat.seatName }}
            </h5>
          </template>
          <template #cell(Price)="{item}">
            <h5 class="text-dark text-right font-weight-bolder">
              {{ !isEmpty(item.seat.seat.fares) && item.seat.seat.fares[0].paxType === 'ALL' ?
                formatCurrency(item.seat.seat.fares[0].total) :
                !isEmpty(item.seat.seat.fares) && item.seat.seat.fares.find(f => f.paxType === item.passenger.paxType) ?
                  formatCurrency(item.seat.seat.fares.find(f => f.paxType === item.passenger.paxType).total) :
                  0
              }}
            </h5>
          </template>
        </b-table-lite>

        <hr>

        <!--Tổng dịch vụ -->
        <div class="d-flex justify-content-end">
          <div>
            <strong class="d-block font-medium-2">{{ $t('flight.total') }}</strong>
            <i>({{ $t('flight.fareText') }})</i>
          </div>
          <strong class="font-medium-2">{{ formatCurrency(totalSeatPrice) }}</strong>
        </div>
      </b-card>
      <!-- !SECTION -->

      <!-- SECTION - Tổng tiền vé -->
      <div
        v-if="!isEmpty(payments)"
        class="text-airline fw-600 mt-1"
      >
        <div class="text-center">
          <span class="mr-2">
            Tổng tiền vé:
          </span>

          <span class="font-medium-3 fw-700">
            <!-- {{
                (payments.reduce((total, item) => total + item.pricingInfo.total, 0)
                  ? formatCurrency(payments.reduce((total, item) => total + item.pricingInfo.total, 0) + (hideFee ? 0 : totalAgencyFee) - (totalDiscountAmount ? totalDiscountAmount : 0) )
                  : 0)
              }} -->
            {{ formatCurrency(totalPriceBooking) }}
          </span>
        </div>
        <div class="text-center text-warning">
          <span class="font-italic">Lưu ý: Tổng tiền chưa bao gồm phí dịch vụ</span>
        </div>
      </div>

      <b-alert
        :show="hasDiscountVN1A || hasDiscount20PercentVN1A"
        variant="danger"
        class="my-50 pb-50"
      >
        <div class="alert-heading">
          <span class="font-medium-3 fw-700">Quan trọng!</span>
        </div>
        <p
          v-if="hasDiscountVN1A"
          class="mt-50 px-1 font-medium-2 fw-700"
        >
          &bull; Hệ thống nhận thấy bạn đang đặt chuyến bay khứ hồi của Vietnam Airlines có giá khuyến mãi. Vui lòng chọn chức năng " Tách chặng" để tính được giá khuyến mãi hoặc liên hệ booker!
        </p>
        <p
          v-if="hasDiscount20PercentVN1A"
          class="mt-50 px-1 font-medium-2 fw-700"
        >
          &bull; Hệ thống nhận thấy bạn đang đặt vé có khuyến mại xuất giảm DOB 20%, vui lòng chọn chức năng " Tách chặng" để thực hiện thao tác ưu đãi!
        </p>

        <div
          v-if="hasDiscountVN1A || hasDiscount20PercentVN1A"
          class="d-flex-center my-50"
        >
          <BFormCheckbox
            :checked="isSplitItineraries"
            @change="() => $emit('update:isSplitItineraries', !isSplitItineraries)"
          >
            <span class="fw-600 font-medium-3">{{ $t('flight.splitItineraries') }}</span>
          </BFormCheckbox>
        </div>
      </b-alert>

      <b-col
        v-if="errorCheckPrice"
        cols="12"
        class="text-center my-3"
      >
        <span class="text-danger font-weight-bolder">
          Lỗi xác nhận thông tin hành trình !!!
        </span>
      </b-col>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BModal, BCol, BOverlay, BButton, BCard, BTable, BTableLite, BFormCheckbox, BAlert,
} from 'bootstrap-vue'
import {
  ref, computed, watch,
  toRefs,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'

import { formatCurrency, convertISODateTime, resolveAirlineFlightNumber } from '@/@core/utils/filter'
import store from '@/store'
// import { checkDomesticPoint } from '@/constants/selectOptions'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BModal,
    BCol,
    BCard,
    BOverlay,
    BButton,
    BTable,
    BTableLite,
    BFormCheckbox,
    BAlert,
  },
  props: {
    bookingData: {
      type: Object,
      default: () => { },
    },
    isSplitItineraries: {
      type: Boolean,
      required: true,
    },
    hasDiscount20PercentVN1A: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      payNowConfirmPrice,
      getSelectedSeatsDetails,
      getAddonsData,
    } = useBookingHandle()

    const { isSplitItineraries } = toRefs(props)
    const payments = ref(null)
    const bookingId = ref(null)
    const loading = ref(false)
    const errorCheckPrice = ref(false)
    const totalAgencyFee = ref(0)
    const totalAgencyFeeParent = ref(0)
    const totalDiscountAmount = computed(() => props.bookingData.itineraries.reduce((a, c) => a + c?.discountInfo?.discountAmount || 0, 0))
    const hideFee = computed(() => store.getters['userStore/getHideFee'])
    const hideFeeParent = computed(() => store.getters['userStore/getHideFeeParent'])

    const FARE_BASIC_CODE_DISCOUNT = ['2', '4', '6', 'P']
    const regexFareDiscount = /^[A-Z0-9]AP[A-Z0-9]*$/
    const hasDiscountVN1A = computed(() => props.bookingData?.type === 'VN1A'
                                        && props.bookingData?.flightType === 'RT'
                                        && (props.bookingData?.itineraries?.some(trip => trip?.segments?.some(seg => seg.fareBasisCode && FARE_BASIC_CODE_DISCOUNT.includes(seg.fareBasisCode.slice(-1))))
                                         || props.bookingData?.itineraries?.some(trip => trip?.segments?.some(seg => regexFareDiscount.test(seg.fareBasisCode)))
                                        ))

    const totalSeatPrice = ref(0)
    const addonDetails = ref([])
    const totalPriceBooking = ref(0)

    const addonsPriceColumns = [
      { label: 'passenger', key: 'Passenger' },
      { label: 'flightSegment', key: 'flight_segment' },
      { label: 'airline', key: 'Airline' },
      { label: 'Service', key: 'Service' },
      { label: 'Price', key: 'Price' },
      { label: 'Amount', key: 'Amount' },
      { label: 'Total', key: 'Total' },
      // { label: 'Action', key: 'Action' },
    ]

    const seatsPriceColumns = [
      { label: 'passenger', key: 'Passenger' },
      { label: 'flightSegment', key: 'flight_segment' },
      { label: 'airline', key: 'Airline' },
      { label: 'Seat', key: 'Seat' },
      { label: 'Price', key: 'Price' },
      // { label: 'Action', key: 'Action' },
    ]

    const totalPriceAddon = computed(() => {
      let total = 0
      if (addonDetails.value) {
        total = addonDetails.value.reduce((acc, item) => acc + (item.amount * item.addonPrice), 0)
      }
      return total
    })

    const selectedSeatsDetail = computed(() => {
      if (getSelectedSeatsDetails.value) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        totalSeatPrice.value = getSelectedSeatsDetails.value.reduce((total, seat) => total + Number(seat.seat.seat.fares[0].total), 0)
        return getSelectedSeatsDetails.value
      }
      return []
    })

    watch(() => getAddonsData.value, val => {
      addonDetails.value = val
    }, { deep: true, immediate: true })

    function getTraceId(error) {
      const id = error?.e?.response?.headers?.['trace-id'] || ''
      return id ? `Trace-id : \n ${error?.e?.response?.headers?.['trace-id']}` : ''
    }

    function okHandle() {
      const bookingData = cloneDeep(props.bookingData)
      let paxLists

      if (['1B', 'TR', 'JQ'].includes(bookingData.type)) {
        let errGetAdt = false
        paxLists = bookingData.paxLists.map((pax, indexPax) => {
          const resPax = payments.value[indexPax]
          let parentPaxId = ''

          if (['INFANT'].includes(pax.paxType)) {
            const adt = bookingData.paxLists.find(p => p.paxId === pax.parentPaxId)
            const resAdt = payments.value.find(p => p.firstName === adt.firstName && p.lastName === adt.lastName && p.paxType === adt.paxType)
            parentPaxId = resAdt.paxId

            if (!adt || !resAdt || !parentPaxId) { // NOTE: 1B ko trả về parentPaxId, lấy từ `bookingData.paxLists` theo index của res `calculatePrice.paxLists`
              errGetAdt = true
            }
          }

          return {
            ...pax,
            paxId: resPax ? resPax.paxId : pax.paxId,
            parentPaxId,
          }
        })

        if (errGetAdt) {
          Vue.swal({
            title: 'Lỗi cập nhật thông tin',
            text: 'Vui lòng kiểm tra lại thông tin hành khách hoặc liên hệ booker !',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-warning',
            },
          })
          return
        }
      } else {
        paxLists = bookingData.paxLists
      }

      const payload = {
        ...bookingData,
        paxLists,
        splitItineraries: isSplitItineraries.value,
        bookingId: (bookingData.type === 'VN1A' && !isSplitItineraries.value) || ['1A', '1B', 'TR', 'JQ'].includes(bookingData.type)
          ? bookingId.value
          : undefined,
      }

      emit('create-booking', payload)
    }

    function getPaxType(pt) {
      if (pt === 'ADT') return 'ADULT'
      if (['CHD', 'CNN'].includes(pt)) return 'CHILD'
      if (pt === 'INF') return 'INFANT'
      return pt
    }

    function showHandle() {
      errorCheckPrice.value = false
      bookingId.value = null

      if (!payments.value) {
        loading.value = true
        totalAgencyFee.value = 0
        totalAgencyFeeParent.value = 0
        let numberPax = 0

        if (!store.getters['userStore/getRoleMama']) {
        // ANCHOR: Get agency Fee
          const { paxLists, itineraries } = props.bookingData
          paxLists.forEach(pax => {
            if (!['INFANT', 'INF'].includes(pax.paxType)) numberPax += 1
          })
          itineraries.forEach(trip => {
            const agFee = store.getters['userStore/getAgencyFee'](trip.domestic, trip.source)
            const agFeeParent = store.getters['userStore/getAgencyFee'](trip.domestic, trip.source, true)
            // FIXME agFee with transits
            totalAgencyFee.value += (agFee?.amount || 0) * numberPax
            totalAgencyFeeParent.value += (agFeeParent?.amount || 0) * numberPax
          })
        }

        payNowConfirmPrice(props.bookingData)
          .then(res => {
            const { paxLists } = props.bookingData
            payments.value = res.paxLists?.map((p, indexP) => {
              const totalAgFee = (!['INFANT', 'INF'].includes(getPaxType(p.paxType)) && hideFee.value)
                ? (totalAgencyFee.value / numberPax)
                : 0
              const totalAgFeeParent = (!['INFANT', 'INF'].includes(getPaxType(p.paxType)) && hideFeeParent.value)
                ? (totalAgencyFeeParent.value / numberPax)
                : 0
              return {
                paxType: getPaxType(p.paxType),
                firstName: p?.firstName || paxLists?.[indexP]?.firstName || '',
                lastName: p?.lastName || paxLists?.[indexP]?.lastName || '',
                paxId: p.paxId,
                parentPaxId: p.parentPaxId,

                pricingInfo: {
                  ...p.pricingInfo,
                  taxCalc: p.pricingInfo.tax + totalAgFee + totalAgFeeParent,
                  totalCalc: p.pricingInfo.total + totalAgFee + totalAgFeeParent,
                },
              }
            })

            bookingId.value = res.bookingId
            // payments.reduce((total, item) => total + item.pricingInfo.total, 0)
            //  + (hideFee ? 0 : totalAgencyFee)
            //  - (totalDiscountAmount ? totalDiscountAmount : 0)

            totalPriceBooking.value = (payments.value.reduce((total, item) => total + item.pricingInfo.total, 0)
              + (hideFee.value ? totalAgencyFee.value : 0)
              + (hideFeeParent.value ? totalAgencyFeeParent.value : 0)
              - (totalDiscountAmount.value ? totalDiscountAmount.value : 0))
              + (totalSeatPrice.value + totalPriceAddon.value)
          })
          .catch(e => {
            console.error({ e })
            errorCheckPrice.value = true

            Vue.swal({
              title: 'Lỗi xác nhận giá!',
              text: `${e} \n ${getTraceId({ e })}`,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-warning',
              },
            })

            this.$bvModal.hide('create-booking-verify-and-confirm-price')
          })
          .finally(() => {
            loading.value = false
          })
      } else {
        loading.value = false
      }
    }

    function getTotalOneTrip(payments) {
      const result = payments.reduce((total, item) => total + item.pricingInfo.total, 0) + (hideFee.value ? totalAgencyFee.value : 0) + (hideFeeParent.value ? totalAgencyFeeParent.value : 0) - (totalDiscountAmount.value || 0)
      return formatCurrency(result) || 0
    }

    function hideModal() {
      payments.value = null
    }

    // https://discord.com/channels/1054696448110903327/1319513893080662098
    // update 26/12 => Bỏ https://discord.com/channels/1054696448110903327/1319513893080662098/1321740983343255584
    // const isTransitDomesticVN1A = computed(() => props.bookingData.itineraries
    //   .some(itinerary => itinerary.source.includes('VN1A')
    //       && itinerary.segments
    //         .some(
    //           (segment, segmentIndex) => (segmentIndex !== (itinerary.segments.length - 1))
    //           && checkDomesticPoint(segment.arrival),
    //         )
    //       && ['INTERNAL'].includes(itinerary?.clientId)))
    return {
      loading,
      errorCheckPrice,
      convertISODateTime,
      showHandle,
      payments,
      isEmpty,
      okHandle,
      formatCurrency,
      hideModal,
      // totalAgencyFee,
      // hideFee,
      hasDiscountVN1A,
      totalDiscountAmount,

      addonsPriceColumns,
      seatsPriceColumns,
      addonDetails,
      totalPriceAddon,
      selectedSeatsDetail,
      totalSeatPrice,

      getTotalOneTrip,
      totalPriceBooking,
      resolveAirlineFlightNumber,
      // isTransitDomesticVN1A,
    }
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
